var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TModal',{attrs:{"title":_vm.title,"size":"lg","show":_vm.show,"fetching":_vm.fetching},on:{"update:show":function($event){return _vm.$emit('update:show', $event)}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.hasRemoveBoxesListener)?_c('div',{staticClass:"w-100 d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"d-flex"},[_c('TMessage',{attrs:{"content":"Selected","bold":""}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.skuSelected.length))]),(_vm.skuSelected.length < _vm.data_table.length)?_c('CLink',{staticClass:"ml-2 d-flex align-items-center",on:{"click":_vm.onSelectAll}},[_c('CIcon',{staticClass:"mr-1",attrs:{"name":"cil-check"}}),_c('TMessage',{attrs:{"content":"Select all","bold":""}})],1):_vm._e(),(_vm.skuSelected.length)?_c('CLink',{staticClass:"ml-2 d-flex text-danger align-items-center",on:{"click":function($event){_vm.skuSelected = []}}},[_c('CIcon',{staticClass:"mr-1",attrs:{"name":"cil-x-circle"}}),_c('TMessage',{attrs:{"content":"Deselect all","bold":"","color":"text-danger"}})],1):_vm._e()],1),_c('div',[(_vm.removing || _vm.fetching)?_c('TSpinner',{attrs:{"loading":_vm.removing || _vm.fetching}}):_vm._e(),(!!_vm.skuSelected.length && !_vm.removing)?_c('TButtonRemove',{attrs:{"options":{ content: 'Unload box from container' }},on:{"click":function($event){return _vm.$emit('remove-boxes', _vm.skuSelected)}}}):_vm._e()],1)]):_c('span')]},proxy:true}])},[(_vm.hasRemoveBoxesListener)?_c('SInputSku',{attrs:{"disabled":!_vm.data_table.length,"placeholder":"Select SKU"},on:{"enter":_vm.onPickSku}}):_vm._e(),_c('TTableSimple',{attrs:{"fields":_vm.referenceFields,"items":_vm.data_table,"noResult":""},scopedSlots:_vm._u([{key:"_",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[(!item.removing)?_c('TButtonRemove',{on:{"click":function($event){return _vm.$emit('remove', item, $event)}}}):_c('TSpinner',{attrs:{"loading":true}}),(_vm.hasRemoveBoxesListener)?_c('div',{staticClass:"ml-3"},[_c('TInputCheckbox',{attrs:{"checked":_vm.lodash.findIndex(_vm.skuSelected, ['id', item.id]) !== -1},on:{"change":function($event){return _vm.selectSku(item)}}})],1):_vm._e()],1)])]}},{key:"quantity_picked",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageNumber',{attrs:{"value":item.quantity_picked,"editable":""},on:{"change":function($event){return _vm.$emit('focus-quantity:change', item, $event)}}})],1)]}},{key:"referer_quantity",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageNumber',{attrs:{"value":item.referer_quantity}})],1)]}},{key:"updated_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageDateTime',{attrs:{"content":item.updated_at}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }